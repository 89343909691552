import { SETTINGS_REDUCER } from "../actions/settings";

export interface IUpdateImageSettingsData {
  id?: number;
  build?: string;
  renderHeight?: number;
  renderWidth?: number;
  imageResolution?: number;
  renderType?: string;
  imageType?: string;
  renderWarmup?: number;
  projectId: number;
}

export interface IWebGlSettingsData {
  projectId: number;
  playcanvasUrl?: string;
}

export interface IStreamSettingsData {
  projectId: number;
  build?: string;
  buildOption?: number;
  resolutionScale: number;
  distance: number;
  antiAliasing: number;
  postProcessing: number;
  shadows: number;
  globalIllumination: number;
  reflections: number;
  textures: number;
  effects: number;
  foliage: number;
  shading: number;
}

export interface IProjectSettings {
  imageSettings: IUpdateImageSettingsData;
  webGlSettings: IWebGlSettingsData;
  streamSettings: IStreamSettingsData;
}

const defaultStreamValue = 4;

const initialState: IProjectSettings = {
  imageSettings: { projectId: -1 },
  webGlSettings: { playcanvasUrl: "", projectId: -1 },
  streamSettings: {
    projectId: 0,
    build: "",
    resolutionScale: defaultStreamValue,
    distance: defaultStreamValue,
    antiAliasing: defaultStreamValue,
    postProcessing: defaultStreamValue,
    shadows: defaultStreamValue,
    globalIllumination: defaultStreamValue,
    reflections: defaultStreamValue,
    textures: defaultStreamValue,
    effects: defaultStreamValue,
    foliage: defaultStreamValue,
    shading: defaultStreamValue,
  },
};

export function settings(state = initialState, action: any): IProjectSettings {
  switch (action.type) {
    case SETTINGS_REDUCER.SET_IMAGE_SETTING: {
      return {
        ...state,
        imageSettings: action.payload,
      };
    }

    case SETTINGS_REDUCER.SET_WEBGL_SETTING: {
      return {
        ...state,
        webGlSettings: action.payload,
      };
    }

    case SETTINGS_REDUCER.SET_STREAM_SETTING: {
      return {
        ...state,
        streamSettings: action.payload,
      };
    }

    default:
      return state;
  }
}
